import instance from "src/AxiosInstance";
import { headers } from "src/utils/headers";

export const fetchClassroomsService = async () => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/classroom?pageToken`, {
        method: 'GET',
        headers: headers
    });
    return response;
}

export const fetchClassroomsServiceUsingToken = async (token) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/classroom?pageToken=${token}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}

export const fetchAssignmentsService = async (id) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/assignment?classroomId=${id}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}


export const fetchAssignmentsServiceUsingToken = async (id,token) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/assignment?classroomId=${id}pageToken=${token}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}


export const fetchGoogleStudentsService = async (id) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/google-student?classroomId=${id}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}

export const fetchGoogleStudentsServicePerAssignment = async (id,assignmentId) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/google-student?classroomId=${id}&assignmentId=${assignmentId}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}
