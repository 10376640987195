import instance from "src/AxiosInstance";
import { headers } from "src/utils/headers";

export const fetchPdfUrl = async (classroomId) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/assignment?classroomId=${classroomId}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}

export const getUploadId = async (classroomId, assignmentId, hId) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/student-submission?classroomId=${classroomId}&assignmentId=${assignmentId}&recordNumber=${hId}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}

export const uploadAssignmentService = async (method, formdata) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/student-submission`, {
        method: method,
        headers: { ...headers, "Content-Type": "multipart/form-data", },
        data: formdata
    });
    return response;
}

export const assignmentTurnInService = async (data) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/student-submission/turn-in`, {
        method: "POST",
        headers: { ...headers, "Content-Type": "application/json", },
        data: JSON.stringify(data)
    });
    return response;
}

export const assignmentChangeStatusService = async (data) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/student-submission/change-status`, {
        method: "PATCH",
        headers: { ...headers, "Content-Type": "application/json", },
        data: JSON.stringify(data)
    });
    return response;
}