import instance from "src/AxiosInstance";
import { headers } from "src/utils/headers";

export const uploadAssignment = async(formdata) =>{ 
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/assignment`, {
        method: 'POST',
        headers: { ...headers, "Content-Type": "multipart/form-data", },
        data: formdata
    });
    return response;
}

export const fetchClassroomsService = async () => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/classroom`, {
        method: 'GET',
        headers: headers
    });
   return response;
}


export const fetchAssignmentServices = async (classId, assigmentTab) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/assignment?classroomId=${classId}&status=${assigmentTab}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}

export const fetchAssignmentUsingTokenServices = async (classId, assigmentTab, nextPageToken) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/assignment?classroomId=${classId}&status=${assigmentTab}&pageToken=${nextPageToken}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}

export const fetchSubmissionsServices = async (classroomId,assignmentId, hId) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/student-submission?classroomId=${classroomId}&assignmentId=${assignmentId}&recordNumber=${hId}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}

export const getStudentSubmissionForTeacherStuddServices = async (classId, assignmentId, id, hId) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/student-submission?classroomId=${classId}&assignmentId=${assignmentId}&studentId=${id}&recordNumber=${hId}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}


export const fetchAssignmentByStudentIdServices = async (classId, assigmentTab, assignmentId, studentId) => {
    const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/assignment?classroomId=${classId}&studentId=${studentId}&assignmentId=${assignmentId}&status=${assigmentTab}`, {
        method: 'GET',
        headers: headers
    });
    return response;
}