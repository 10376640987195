export const teacherColors = [
    {
        title: 'red',
        color: '#ff0019'
    },
    {
        title: 'yellow',
        color: '#ffc400'
    },
    {
        title: 'black',
        color: '#000000'
    },
    {
        title: 'green',
        color: '#59ff0d'
    },
    {
        title: 'pink',
        color: '#f700ff'
    },
    {
        title: 'purple',
        color: '#8000ff'
    },
    {
        title: 'cyan',
        color: '#00c9c3'
    },
    {
        title: 'blue',
        color: '#021ff7'
    },
]

export const studentColors = [
    {
        title: 'black',
        color: '#000000'
    },
    {
        title: 'yellow',
        color: '#ffc400'
    },
    {
        title: 'green',
        color: '#59ff0d'
    },
    {
        title: 'pink',
        color: '#f700ff'
    },
    {
        title: 'purple',
        color: '#8000ff'
    },
    {
        title: 'cyan',
        color: '#00c9c3'
    },
    {
        title: 'blue',
        color: '#021ff7'
    },

]