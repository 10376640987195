import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

import { Box, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Page = forwardRef(({ children, disabled = false, goBack=true, title = '', meta, ...other }, ref) => {

  const navigate = useNavigate();

  return <>
    <Helmet>
      <title>{`${title} | STEMboard`}</title>
      {meta}
    </Helmet>
    {goBack && <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} display={window.innerWidth > 500 ? "block" : "none"}>
    <Button disabled={disabled} onClick={()=> navigate(-1)}><ArrowBackIcon/></Button>
    </Stack>}
    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
