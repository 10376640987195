import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from '../components/Page';
import { AssignmentPopUp } from 'src/components/popups/assignmentsPopUp';
import { useStyles } from 'src/styles/components/styles';
import { useParams } from 'react-router-dom';
import { fetchAssignmentsService, fetchGoogleStudentsServicePerAssignment } from 'src/services/Classroom.services';
import GoogleStudents from 'src/components/googleStudentsList';

export default function AssigmentsTeacher() {
    const classes = useStyles();
    const [spinner, setSpinner] = useState(false);
    const [open, setOpen] = useState(false);
    const [studentsList, setStudentsList] = useState([]);
    const { classroomId, assignmentId } = useParams();
    const [assignmentTitle, setAssignmentTitle] = useState('');

    React.useEffect(() => {
        const fetchSubmissions = async () => {
            setSpinner(true);
            const response = await fetchGoogleStudentsServicePerAssignment(classroomId,assignmentId)
            const data = await response.data;
            setStudentsList(data?.data?.students || []);
            setSpinner(false);
        }
        if (classroomId)
            fetchSubmissions();
        // eslint-disable-next-line
    }, [classroomId]);

    React.useEffect(() => {
        const fetchSubmissions = async () => {
            const response = await fetchAssignmentsService(classroomId)
            const data = await response.data;
            setAssignmentTitle(data?.data?.courseWork.find(course => course.id === assignmentId).title);
        }
        if (classroomId)
            fetchSubmissions();
        // eslint-disable-next-line
    }, [classroomId]);
    
    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <Page title="Assignments">
            <Container>
                <AssignmentPopUp open={open} handleOpen={handleOpen} handleClose={handleClose} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {assignmentTitle}
                    </Typography>
                </Stack>
                {spinner ?

                    <Grid item xs={12} sm={12} md={12} className={classes.spinnerGrid}>
                        <CircularProgress />
                    </Grid>
                    :
                    <GoogleStudents students={studentsList} classroomId={classroomId} assignmentId={assignmentId} />
                }
            </Container>
        </Page>
    );
}
