import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useStyles } from 'src/styles/components/styles';
import AssignmentCardToWhiteboard from './AssignmentCardToWhiteboard';
import { MESSAGES } from 'src/constants/ResponseMessages';

AssignmentListForTeacher.propTypes = {
    assignments: PropTypes.array.isRequired
};

export default function AssignmentListForTeacher({ assignments, studentId, classroomId, ...other }) {
    const classes = useStyles();
    return (
        <Grid container spacing={2} {...other}>
            {assignments?.length > 0 ? assignments?.map((assignment) => (
                <Grid key={assignment.id} item xs={12} sm={6} md={3}>
                    <AssignmentCardToWhiteboard assignments={assignment} classroomId={classroomId} studentId={studentId} /> 
                </Grid>
            )) :
                <Grid item xs={12} sm={12} md={12} className={classes.spinnerGrid}>
                    <Typography className={classes.notFoundHeading}>No Assignments.
                    <p className={classes.notFoundSubHeading}>{MESSAGES.ALLOW_SCOPES}</p>
                    </Typography>
                </Grid>
            }
        </Grid>
    );
}
