import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from '../components/Page';
import { AssignmentPopUp } from 'src/components/popups/assignmentsPopUp';
import { useStyles } from 'src/styles/components/styles';
import { STATUS } from 'src/constants/AssignmentStatus';
import { fetchAssignmentByStudentIdServices } from 'src/services/UploadAssignment.services';
import { useParams } from 'react-router-dom';
import AssignmentListForTeacher from 'src/sections/@dashboard/assignments/AssignmentListForTeacher';

export default function AssigmentPerStudent() {
    const classes = useStyles();
    const [spinner, setSpinner] = useState(false);
    const [open, setOpen] = useState(false);
    const [assignmentList, setAssignmentList] = useState([]);
    const [assigmentTab, setAssignmentTab] = useState(STATUS.PENDING);
    const { studentId, classroomId, assignmentId, name} = useParams();

    React.useEffect(() => {
        const fetchAssignments = async () => {
            setSpinner(true);
            const response = await fetchAssignmentByStudentIdServices(classroomId, assigmentTab, assignmentId, studentId);
            const data = await response.data;
            setAssignmentList(data?.data?.courseWork || []);
            setSpinner(false);
        }
        if (classroomId)
            fetchAssignments();
        // eslint-disable-next-line
    }, [assigmentTab, classroomId]);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <Page title="Assignments">
            <Container>
                <AssignmentPopUp open={open} handleOpen={handleOpen} handleClose={handleClose} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Assignments of {name}
                    </Typography>
                </Stack>

                <Stack className={classes.flexDivEvenlyRow}>
                    <Typography className={assigmentTab === STATUS.PENDING ? classes.activeTab : classes.inactiveTab} onClick={() => setAssignmentTab(STATUS.PENDING)}>Pending</Typography>
                    <Typography className={assigmentTab === STATUS.INPROGRESS ? classes.activeTab : classes.inactiveTab} onClick={() => setAssignmentTab(STATUS.INPROGRESS)} >In Progress</Typography>
                    <Typography className={assigmentTab === STATUS.GRADED ? classes.activeTab : classes.inactiveTab} onClick={() => setAssignmentTab(STATUS.GRADED)} >Completed</Typography>
                </Stack>
                {spinner ?

                    <Grid item xs={12} sm={12} md={12} className={classes.spinnerGrid}>
                        <CircularProgress />
                    </Grid>
                    :
                    <AssignmentListForTeacher studentId={studentId} assignments={assignmentList} classroomId={classroomId} />
                }
            </Container>
        </Page>
    );
}
