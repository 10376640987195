
import { ListNames, Names } from 'src/constants/ListNames';
import Iconify from '../../components/Iconify';


const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const SuperAdminsNavConfig = [
  // {
  //   title: 'category',
  //   path: '/dashboard/category',
  //   icon: getIcon('ic:baseline-category'),
  // },
  {
    title: Names.Principals,
    path: `/dashboard/${ListNames.Principals}`,
    icon: getIcon('eva:people-fill'),
  }
];

export default SuperAdminsNavConfig;
