import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import React,{Suspense} from 'react'
import { useStyles } from 'src/styles/components/styles'

export const SuspenseFallback = ({children}) => {
  const classes = useStyles();
  return (
    <Suspense fallback={<Grid item xs={12} sm={12} md={12} className={classes.spinnerGrid}>
      <CircularProgress />
    </Grid>}>
       {children}
    </Suspense>
  )
}
