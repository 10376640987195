import React from "react";
import { Box, Container } from '@mui/material';
import Page from '../components/Page';
import { Roles } from "src/constants/Role";
import { localStorageEnums } from "src/constants/LocalStorageEnums";
import SimpleBackdrop from "src/components/Backdrop";
import { studentColors, teacherColors } from "src/constants/Colors";
import "../styles/pages/wbPage.css";
import { useNavigate, useParams } from "react-router-dom";
import { Whiteboard } from "react-whiteboard-stem";
import swal from "sweetalert";
import { assignmentChangeStatusService, assignmentTurnInService, fetchPdfUrl, getUploadId, uploadAssignmentService } from "src/services/WBPage.services";
import { getStudentSubmissionForTeacherStuddServices } from "src/services/UploadAssignment.services";
import { statusEnums } from "src/constants/Status";
import { MESSAGES } from "src/constants/ResponseMessages";
import { STATUS } from "src/constants/AssignmentStatus";
import StyledSnackbar from "src/components/StyledSnackbar";


export default function WBPage() {
    const navigate = useNavigate();
    const formdata = new FormData();
    const { classroomId, assignmentId, studentId } = useParams();
    const [pdfUrl, setPdfUrl] = React.useState(null);
    const [submissionId, setSubmissionId] = React.useState('');
    const [historyId, setHistoryId] = React.useState(1);
    const [historyCount, setHistoryCount] = React.useState(0);
    const [resendFiles, setResendFiles] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [revision, setRevision] = React.useState(false);
    const [buttonFlag, setButtonFlag] = React.useState(false);
    const [spinnerPDF, setSpinnerPDF] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);

    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const [open, setOpen] = React.useState(false);
    const [screenWidth, setScreenWidth] = React.useState('');
    const [canvasJSON, setCanvasJSON] = React.useState([]);
    const [previousCanvasJSON, setPreviousCanvasJSON] = React.useState([]);
    const [historyCanvasJSON, setHistoryCanvasJSON] = React.useState([]);

    const [snackbarData, setSnackBarData] = React.useState({
        xPos: 'center',
        yPos: 'bottom',
        title: '',
        status: 'success'
    });

    const [openSnack, setOpenSnack] = React.useState(false);
    const handleClick = () => {
        setOpenSnack(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    let requestBody = {};

    React.useEffect(() => {
        const fetchPdf = async () => {
            setSpinnerPDF(true);
            setPdfUrl(null);
            const response = await fetchPdfUrl(classroomId);
            const data = await response.data;
            setPdfUrl(data?.data?.courseWork.find((item) => item.id === assignmentId)?.materials[0]?.link?.url || data?.data?.courseWork.find((item) => item.id === assignmentId).materials[0].driveFile.driveFile.alternateLink);
            setSpinnerPDF(false);
        }
        if (classroomId)
            fetchPdf();
        // eslint-disable-next-line
    }, [classroomId, assignmentId]);

    React.useEffect(() => {
        const propertyValues = Object.values(canvasJSON);
        if (canvasJSON && screenWidth ) {
            if (previousCanvasJSON.length > 0)
                UploadAssignment({ screen: previousCanvasJSON[0].screen, object: propertyValues });
            else
                UploadAssignment({ screen: screenWidth, object: propertyValues });
        }
        // eslint-disable-next-line
    }, [canvasJSON, screenWidth]);

    const checkButtonFlag = (data) => {

        if (data?.data?.assignmentSubmission?.attachments && data?.data?.assignmentSubmission?.attachments?.length >= 20) {
            return false;
        }
        if (userData.role === Roles.Teacher && data?.data?.status === STATUS.INPROGRESS) {
            return true;
        }
        else if (userData.role === Roles.Student && data?.data?.status === STATUS.PENDING)
            return true;
        return false;
    }

    React.useEffect(() => {
        const fetchPdf = async () => {
            setSpinner(true);
            const response = await getStudentSubmissionForTeacherStuddServices(classroomId, assignmentId, studentId, historyId);
            const data = await response.data;
            setHistoryCanvasJSON([...historyCanvasJSON, data]);
            setSubmissionId(data?.data?.id || '');
            setHistoryCount(data?.data?.submissionCount || 0);
            if (data?.data?.submission.length >0) {
                setCanvasJSON([data?.data?.submission[0]?.submission] || []);
                setPreviousCanvasJSON([data?.data?.submission[0]?.submission] || []);
            }
            setButtonFlag(checkButtonFlag(data));
            if (data?.data?.assignmentSubmission?.attachments) {
                setRevision(data?.data?.status === STATUS.PENDING);
            }
            setButtonFlag(historyId === 1);
            if (!checkButtonFlag(data)){
                setButtonFlag(false)
            }
            setSpinner(false);
        }
        if (classroomId)
            fetchPdf();
        // eslint-disable-next-line
    }, [classroomId, assignmentId, historyId]);

    const UploadAssignment = async (canvas) => {
        try {
            setOpen(true);
            const student = userData.role === Roles.Student;
            let id = submissionId;
            let data = "";
            let status = "";
            requestBody.classroomId = classroomId;
            requestBody.assignmentId = assignmentId;
            requestBody.assignments = canvas;

            if (student) {
                const response = await getUploadId(classroomId, assignmentId, historyId);
                data = await response.data;
                id = data?.data?.id;
                requestBody.id = id;
                formdata.append('id', id);
            }
            else {
                status = resendFiles ? statusEnums.REVISE : statusEnums.GRADED;
                id = submissionId;
                requestBody.id = submissionId;
                requestBody.status = status;
                formdata.append('id', submissionId);
                formdata.append('status', status);
            }
            let requestJSON = {
                "classroomId": classroomId,
                "assignmentId": assignmentId,
                "id": id,
                "assignments": canvas
            }

            formdata.append('classroomId', classroomId);
            formdata.append('assignmentId', assignmentId);
            if (Object.values(files).length > 0) {
                for (let i = 0; i < Object.values(files).length; i++) {
                    formdata.append('file', Object.values(files)[i]);
                }
            }

            await uploadAssignmentService(student ? "POST" : "PATCH", formdata);

            if (student) {
                await assignmentTurnInService(requestJSON);
            }
            else {
                await assignmentChangeStatusService({ ...requestJSON, "status": status });
            }
            setOpen(false);
            let conditional_msg = historyCount >= 18 ? "This is your last attempt. You will not be allowed to resubmit the assignment." : `You have ${20 - historyCount - 1} attempts left.`
            student ?
                swal("Success", `${MESSAGES.INPROGRESS_MSG} \n${conditional_msg}`, "success").then(() => window.location.href = "/") :
                resendFiles ? swal("Success", `${MESSAGES.REVISE_MSG}\n${conditional_msg}`, "success").then(() => window.location.href = "/") : swal("Success", `${MESSAGES.GRADED_MSG}\n${conditional_msg}`, "success").then(() => window.location.href = "/");

        }
        catch (err) {
            swal("Info", err.response.data.message, "info");
            setOpen(false);
        }
    }

    const confirmHistoryIdChange=(value, action, msg)=>{

        if(open) return;
        swal({
            title: 'Are you sure?',
            text: msg,
            icon: 'warning',
            customClass: 'Custom_Cancel',
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                if(action === "previous"){
                    setHistoryId(value + 1);
                  

                }
                else
                {
                    setHistoryId(value - 1) ;
                

                }
            } else {
                return;
            }
        });
    }

    return (
        <Page title="WhiteBoard" goBack={false}>
            {open && <SimpleBackdrop open={true} />}
            <Container maxWidth="xl" sx={{ mt: 0 }}>
                <div onClick={() => { navigate("/") }}>
                    <img alt="stemboard_logo" src='/static/illustrations/illustration_login.png' className="fixedlogo" />
                </div>
                <button className="floating_history_button_left"  onClick={() => {
                    if (historyId + 1 > historyCount) {
                        setSnackBarData({
                            xPos: 'center',
                            yPos: 'bottom',
                            title: 'No more previous submissions found.',
                            status: 'info'
                        });
                        handleClick();
                        return;
                    }
                    confirmHistoryIdChange(historyId, "previous", "You want to preview previous submissions.\nYour current changes will be cleared.")
                }
                    }>
                    <Box >
                        <img src="/static/icons/history_prev.png" width={20} height={20} alt="history_back"/>
                    </Box>
                </button>
                <button className="floating_history_button_right" onClick={() => {
                    if (historyId - 1 < 1) {
                        setSnackBarData({
                            xPos: 'center',
                            yPos: 'bottom',
                            title: 'You are viewing the latest submission.',
                            status: 'info'
                        });
                        handleClick();
                        return;
                    }
                    confirmHistoryIdChange(historyId, "next", "You want to preview next submissions.\nYour current changes will be cleared.")
                }
                    }><Box >
                        <img src="/static/icons/history_next.png" width={20} height={20} alt="history_next"/>
                    </Box></button>
                {(spinner || spinnerPDF) ? <SimpleBackdrop open={true} /> :
                     <Whiteboard revision={revision} aspectRatio={4 / 6} setFiles={setFiles} setResendFiles={setResendFiles} pdf={pdfUrl} color={userData?.role === Roles?.Teacher ? teacherColors : studentColors} pdfUrl={pdfUrl} resend={Roles.Teacher === userData.role} buttonFlag={buttonFlag} json={canvasJSON} setJSON={setCanvasJSON} jsonScreenWidth={screenWidth} setJSONScreenWidth={setScreenWidth} />
                }
            </Container>
            <StyledSnackbar
                xPos={snackbarData.xPos}
                yPos={snackbarData.yPos}
                title={snackbarData.title}
                status={snackbarData.status}
                open={openSnack}
                onClose={handleCloseSnack}
            />
        </Page>
    );
}