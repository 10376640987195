import { ListNames, Names } from 'src/constants/ListNames';
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'Video',
  //   path: '/dashboard/videos',
  //   icon: getIcon('akar-icons:youtube-fill'),
  // },
  {
    title: Names.Students,
    path: `/dashboard/${ListNames.Students}`,
    icon: getIcon('eva:people-fill'),
  },
  {
    title: Names.Teachers,
    path: `/dashboard/${ListNames.Teachers}`,
    icon: getIcon('eva:people-fill'),
  }
];

export default navConfig;
