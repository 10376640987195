import axios from 'axios';
import { AES, enc } from 'crypto-js';
import { localStorageEnums } from './constants/LocalStorageEnums';
import { headers } from './utils/headers';

const instance = axios.create({
  headers: {}
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(localStorageEnums.TOKEN);
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && window.localStorage.getItem(localStorageEnums.EMAIL)) {
      const response = await instance(`${process.env.REACT_APP_STEMBOARD_API}/auth/login`, {
        method: 'POST',
        headers,
        data: JSON.stringify({
          email: window.localStorage.getItem(localStorageEnums.EMAIL),
          password: AES.decrypt(
            window.localStorage.getItem(localStorageEnums.PASSWORD),
            process.env.REACT_APP_SECRET
          ).toString(enc.Utf8)
        })
      });
      const data = await response.data;
      if (response?.status === 200) {
        window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(data.data.userDetails));
        window.localStorage.setItem(localStorageEnums.TOKEN, data.meta.token);
        window.localStorage.setItem(localStorageEnums.USERID, data.data.userDetails.id);
        window.location.reload();
      } else if (response?.status === 400) {
        window.localStorage.clear();
        window.location.reload();
      }
    } else if (error?.response?.status === 401 && !window.localStorage.getItem(localStorageEnums.EMAIL)) {
      window.localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;
