
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const studentConfig = [
  // {
  //   title: 'Video',
  //   path: '/dashboard/videos',
  //   icon: getIcon('akar-icons:youtube-fill'),
  // },
  {
    title: 'Assigments',
    path: '/dashboard/assignments',
    icon: getIcon('ic:round-assignment'),
  }
];

export default studentConfig;
