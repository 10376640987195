
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const teacherConfig = [
  // {
  //   title: 'Video',
  //   path: '/dashboard/videos',
  //   icon: getIcon('akar-icons:youtube-fill'),
  // },
  {
    title: 'Classrooms',
    path: '/dashboard/classrooms',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Add Assignment',
    path: '/dashboard/upload-assignment',
    icon: getIcon('eva:plus-fill'),
  }
];

export default teacherConfig;
