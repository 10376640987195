import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useStyles } from 'src/styles/components/styles';

const AssignmentImgStyle = styled('img')({
    top: 0,
    width: '50%',
    margin: 'auto',
    height: '100%',
    objectFit: 'contain'
});

AssignmentCardToWhiteboard.propTypes = {
    assignment: PropTypes.object,
};

export default function AssignmentCardToWhiteboard({ assignments, classroomId, studentId }) {
    const classes = useStyles();
    const { title, id } = assignments;

    return (
        <Card>
            <Box className={classes.cardMaxHeight}>
                <AssignmentImgStyle alt={title} src="/static/icons/assignment.png" />
            </Box>
            <Stack spacing={1} sx={{ p: 3 }}>
                <Link to={`/whiteboard/${classroomId}/${id}/${studentId}`} color="inherit" underline="hover" component={RouterLink}>
                    <Typography className={classes.cardTitleBold} title={title} noWrap>
                        {title}
                    </Typography>
                </Link>
            </Stack>
        </Card>
    );
}
