import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TransitionsModal from './fadingPopUpWrapper';
import { useStyles } from 'src/styles/components/styles';

export const AssignmentPopUp = (props) => {
    const classes = useStyles();
  const [values, setValues] = useState({
    link: props?.data?.link || '',
    title: props?.data?.title || '',
    desc: props?.data?.desc || '',
    category: props?.data?.category || ''
  });
  const [errors, setErrors] = useState({
    link: false,
    title: false,
    category: false,
    desc : false
  });

  useEffect(()=>{
    setValues({
    link: props?.data?.link || '',
    title: props?.data?.title || '',
    desc: props?.data?.desc || '',
    category: props?.data?.category || ''
  });
  setErrors({
    link: false,
    title: false,
    category: false,
    desc : false
  });
  setSpinner(false);
  // eslint-disable-next-line
  },[props?.open])


  const [name, setName] = useState('Add');

  const [spinner, setSpinner] = useState(false);

  const addAssigmentHandler = async () => {
    if (values.link && values.title && values.desc && values.category ) {
      setSpinner(true);
    
    }
     else
      setErrors({
        link: values.link?.length === 0,
        title: values.title?.length === 0,
        desc: values.desc?.length === 0,
        category: values.category?.length === 0,
      });
  };

  useEffect(() => {
    setValues({
      link: props?.data?.link || '',
      title: props?.data?.title || '',
      desc: props?.data?.desc || '',
      category: props?.data?.category || '',
    });
    if (props?.data?.name) {
      setName('Edit');
    }
    else{
      setName('Add');
    }
  }, [props.data, name]);

  const editThreeDModelHandler = async () => {
    setSpinner(true);
    if (values.link  && values.title  && values.desc  && values.category ) {
   
    }
  };

  const clickHandler = () => {
    if (name === 'Edit') {
      editThreeDModelHandler();
    } else {
      addAssigmentHandler();
    }
  };

  const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
      setErrors({ ...errors, [prop]: event.target.value === '' });
  };

  return (
    <TransitionsModal open={props?.open} handleClose={props?.handleClose} handleOpen={props?.handleOpen}>
      <Typography id="modal-modal-title" variant="h6" component="h2" textAlign="center" >
        {name} Assigment
      </Typography>
      <TextField
        id="outlined-adornment-name-registration"
        type="text"
        className={classes.fullWithTextField}
        value={values.link}
        onChange={handleChange('link')}
        label="Link"
        color="primary"
        autoComplete="off"
        error={errors.link}
        helperText={errors.link ? 'Link is required.' : null}
      />
      <TextField
        id="outlined-adornment-name-registration"
        type="text"
        className={classes.fullWithTextField}
        value={values.title}
        onChange={handleChange('title')}
        label="Title"
        color="primary"
        autoComplete="off"
        error={errors.title}
        helperText={errors.title ? 'Title is required.' : null}
      />
      <TextField
        id="outlined-adornment-name-registration"
        type="text"
        className={classes.fullWithTextField}
        value={values.desc}
        onChange={handleChange('desc')}
        label="Description"
        color="primary"
        autoComplete="off"
        error={errors.desc}
        helperText={errors.desc ? 'Description is required.' : null}
      />
      <TextField
        id="outlined-adornment-name-registration"
        type="text"
        className={classes.fullWithTextField}
        value={values.category}
        onChange={handleChange('category')}
        label="Category"
        color="primary"
        autoComplete="off"
        error={errors.category}
        helperText={errors.category ? 'Category is required.' : null}
      />
      <div className={classes.flexDivWithCenter}>
        {spinner ? (
          <CircularProgress className={classes.buttonMargin} />
        ) : (
          <Button
            variant="contained"
            className={classes.buttonMargin}
            onClick={clickHandler}
          >
            {name} Assigment
          </Button>
        )}
      </div>
    </TransitionsModal>
  );
};
