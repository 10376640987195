
import { makeStyles } from '@mui/styles';
import deepPurple  from '@mui/material/colors/deepPurple';

export const useStyles = makeStyles({
  fullWidthTextField: { width: '100%', marginTop: '15px' },
  eightyWidthTextField: { width: '80%' },
  buttonMargin: { marginTop: '15px', borderRadius:'20px' },
  roundButton: { borderRadius: '20px' },
  boxMargin: { padding: '20px', marginBottom: '15px' },
  flexDivWithCenter: { width: '100%', display: 'flex', justifyContent: 'center' },
  textAlignCenter: { textAlign: 'center' },
  modalTextField: { width: '40px' },
  modalTextSpacing: { paddingTop: '20px', paddingRight: '5px' },
  modalButtonDivFlexWrap: { display: 'flex', justifyContent: 'space-evenly', width: '100%', flexWrap: 'wrap' },
  flexDivEvenly: { width: '100%', display: 'flex', justifyContent: 'space-evenly' },
  flexDivEvenlyRow: { width: '100%', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', marginBottom:'20px' },
  flexDivEvenlyCenter: { width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' },
  flexDivBetweenCenter: { width: '100%', display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginBottom:'25px' },
  flexAlignCenter: { width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' },
  fullWidth: { width: '100%' },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "900",
    paddingLeft: "15px",
    marginBottom: "15px",
  },
  columnFlexWithCenterSpacing: { padding: "15px", marginTop: "25px", display: 'flex', flexDirection: 'column', justifyItems: 'center' },
  cardButton: { margin: 'auto', marginTop: '25px', maxWidth: 'max-content' },
  tableStyle: { width: '100%', maxWidth: 1500 },
  headerButton: { display: 'flex', justifyContent: 'center', marginTop: '25px' },
  addUserTextField: { width: '90%', marginTop: '15px' },
  NineZeroWidth: { width: '90%' },
  addUserOuterDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '15px'
  },
  smallTextfield: { width: '32%', marginTop: '5px' },
  productDropDownSmallWidth: { width: '32%', marginTop: '15px' },
  productDropDownSmallWidthML5: { width: '32%', marginTop: '15px', marginLeft: '5px' },
  productAddButtonMargin: { marginTop: '15px', marginLeft: '5px' },
  addModelDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: '15px'
  },
  flexWrapRowReverseSpBtw: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: '15px'
  },
  tableLeftCell4OrderDetails: {
    fontWeight: "900",
    fontSize: "18px",
    minWidth: "220px",
  },
  mL5: { margin: '5px' },
  mT30: { marginTop: '30px' },
  mT50: { marginTop: '50px' },
  fontRed: { color: 'red' },
  notFoundHeading: {
    fontWeight: '900',
    textAlign: 'center',
    fontSize: '16px',
    marginTop: '20px'
  },
  notFoundSubHeading : {
    fontWeight: '500',
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '3px',
    color:'#595959'
  },
  cardMaxHeight: { height: '250px' },
  cardTitleBold: { fontWeight: '900', fontSize: '18px' },
  clasroomCard: { 
    background: "rgba( 202, 196, 196, 0.25 )",
    boxShadow: "0 0 5px rgba( 31, 38, 135, 0.37 )",
  backdropFilter: "blur(5px)",
webkitBackDropFilter : "blur(5px)",
minHeight :'107px',
borderRadius: "10px",
border: "1px solid rgba(255, 255, 255, 0.18)"},
  boxRelative: { position: 'relative' },
  spinnerGrid: { width: '100%', minHeight: '45vh', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' },
  avatar : {
    backgroundColor: deepPurple[500]
  },
  logoutListItemMargin: { marginRight: '7px', marginLeft: '5px' },
  listItemIcon : { width: '30px', height: '30px', marginLeft: '5px' },
  logo : { borderRadius:'50%' },
  userRoleSideBar: { color : 'white'},
  userNameSideBar: { color: 'white', marginTop:'15px', fontSize:'16px' },
  logoIcon : {
    width: 40, 
    height: 40, 
    borderRadius: '50%', 
    overflow: 'hidden',
  },
  sideBar : {
    backgroundColor: '#100039', color: '#fff'
  },
  activeTab: { fontWeight: "900", color: "black", cursor: 'pointer' },
  inactiveTab: { fontWeight: "500", color: "#ccc", cursor: 'pointer' },
  assignmentGrid: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width:'100%', maxWidth:'500px'},
  uploadAssignmentGrid: {
    display: 'flex',
    width: '100%',
    marginTop: '15px',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
},
visibleHelperText : {
  fontSize:'12px',
  color:'red',
  display:'block'
},
styledSnackbar : {
  zIndex: '9999999'
},
invisibleHelperText: {
    fontSize: '12px',
    color: 'red',
    display: 'none'
  },
  tableContainer: {width: '100%', maxWidth: 2000 }

});

export const inputStyle = { border: '2px solid #100039', height: '2rem', width: '2rem' }