import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material';
import account from '../../_mock/account';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import navConfig from './PrincipalsNavConfig';
import SuperAdminsNavConfig from './SuperAdminsNavConfig';
import teacherConfig from './teacherNavConfig';
import studentConfig from './studentNavConfig';
import { abbreviate, capitalizeFirstLetter } from 'src/utils/formatTypography';
import { Roles } from 'src/constants/Role';
import { localStorageEnums } from 'src/constants/LocalStorageEnums';
import LogoutSection from 'src/components/LogoutSection';
import { useStyles } from 'src/styles/components/styles';
import swal from 'sweetalert';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection:'row',
  alignItems: 'center',
  justifyContent:'center',
  textAlign:'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[200_12],
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const classes = useStyles();

  const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const logoutHandler = () => {

    swal({
      title: 'Are you sure?',
      text: "Do you want to logout.",
      icon: 'warning',
      customClass: 'Custom_Cancel',
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        window.localStorage.removeItem(localStorageEnums.TOKEN);
        window.localStorage.removeItem(localStorageEnums.USERDATA);
        window.location.reload();
      } else {
        return;
      }
    });
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
      className={classes.sideBar}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="myprofile">
          <AccountStyle>
            {userData?.avatar ?
            <Avatar src={userData?.avatar || account.photoURL} alt={`${userData.firstName} ${userData.lastName}` } /> :
            <Avatar src={account.photoURL} alt={`${userData.firstName} ${userData.lastName}` } />  }
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" className={classes.userNameSideBar} title={userData?.name ? userData?.name : `${userData.firstName} ${userData.lastName}`} noWrap>
                {abbreviate(userData?.name ? userData?.name : `${userData.firstName} ${userData.lastName}`,18)}
              </Typography>
              <Typography variant="body2" className={classes.userRoleSideBar} noWrap>
                {capitalizeFirstLetter(userData?.role)}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      { userData.role === Roles.SuperAdmin &&<NavSection navConfig={SuperAdminsNavConfig} />}
     { userData.role === Roles.Principal && <NavSection navConfig={navConfig} />}
     { userData.role === Roles.Teacher && <NavSection navConfig={teacherConfig} />}
     { userData.role === Roles.Student && <NavSection navConfig={studentConfig} />}


      <Box sx={{ height: '15px'}}>
     <LogoutSection title="Logout" logoutHandler={logoutHandler}/>
     </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          style={{ backgroundColor:'transparent' }}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
          className={classes.sideBar}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
