import { createSlice } from '@reduxjs/toolkit'

export const dataManager = createSlice({
  name: 'dataManager',
  initialState: {
    students : [],
    principals : [],
    category: [],
    assignments : [],
    pending :[],
    inprogress : [],
    graded : [],
    videos : [],
    teachers : [],
    classrooms : [],
    currentClassroomPage : 0,
    fetched : []
  },
  reducers: {
    insertStudents: (state,actions) => {
      state.students= actions.payload;
      return state;
    },
    insertCategory: (state,actions) => {
      state.category.push(actions.payload);
      return state;
    },
    resetCategory: (state, actions) => {
      state.category = [];
      state.category.push(actions.payload);
      return state;
    },
    updateClassroomPage : (state,actions) => {
      state.currentClassroomPage = actions.payload;
      return state;
    },
    insertPrincipals: (state,actions) => {
      state.principals.push(actions.payload);
      return state;
    },
    resetPrincipals: (state, actions) => {
      state.principals = [];
      state.principals.push(actions.payload);
      return state;
    },
    fetchedItems : (state,actions) =>{
      state.fetched.push(actions.payload);
      return state;
    },
    insertTeachers: (state,actions) => {
      state.teachers= actions.payload;
      return state;
    },
    insertAssignments: (state,actions) => {
      state.assignments= actions.payload;
      return state;
    },
    insertPendingAssignment: (state, actions) => {
      state.pending.push(actions.payload);
      return state;
    },
    insertInprogressAssignment: (state, actions) => {
      state.inprogress.push(actions.payload);
      return state;
    },
    insertGradedAssignment: (state, actions) => {
      state.graded.push(actions.payload);
      return state;
    },
    insertClassrooms: (state, actions) => {
      state.classrooms.push(actions.payload);
      return state;
    },
    insertVideos: (state,actions) => {
      state.videos= actions.payload;
      return state;
    },
    fetchItems: (state) => {
      return state;
    }
  },
});


export const { insertStudents, insertTeachers, insertAssignments, insertVideos, insertPrincipals, insertPendingAssignment, insertInprogressAssignment, insertGradedAssignment, fetchItems, fetchedItems, insertCategory, insertClassrooms, insertCategoryRefresh, updateClassroomPage, resetCategory, resetPrincipals } = dataManager.actions;

export default dataManager.reducer;