export const ListNames = {
    SuperAdmins : 'admins',
    Principals : 'principals',
    Teachers : 'teachers',
    Students : 'students'
};

export const Names = {
    SuperAdmins : 'Admins',
    Principals : 'Principals',
    Teachers : 'Teachers',
    Students : 'Students'
}