
import Router from './routes';
import ThemeProvider from './theme';
import {GoogleOAuthProvider} from '@react-oauth/google'
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

export default function App() {

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GC_CLIENT_ID}>
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
