import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from '../components/Page';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useStyles } from 'src/styles/components/styles';
import Button from '@mui/material/Button';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import Iconify from 'src/components/Iconify';
import swal from 'sweetalert';
import { fetchClassroomsService, uploadAssignment } from 'src/services/UploadAssignment.services';

export default function AssignmentUpload() {

    const classes = useStyles();

    const [spinner, setSpinner] = useState(false);

    const formdata = new FormData();

    const [values, setValues] = useState({
        name: '',
        assignment: '',
        classroom: []
    });

    const [fileName, setFileName] = useState('');

    const [classrooms, setClassrooms] = useState([]);

    const [classroomChecked, setClassroomChecked] = useState([]);

    const [buttonSpinner, setButtonSpinner] = useState(false);

    const [errors, setErrors] = useState({
        name: false,
        assignment: false,
        classroom: false
    })

    const handleChange = (e, prop) => {
        if (prop === "docs") {
            setFileName(e.target.files[0].name);
            setValues({ ...values, assignment: e.target.files[0] });
            setErrors({ ...errors, assignment: false });
        }
        else
            setValues({ ...values, [prop]: e.target.value });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            if (values.name && values.assignment && classroomChecked.length > 0) {
                setButtonSpinner(true);
                formdata.append('file', values.assignment);
                formdata.append('assignmentName', values.name);
                formdata.append('classroomId[]', classroomChecked);
                const response = await uploadAssignment(formdata);
                const data = await response.data;
                setButtonSpinner(false);
                if (response.status === 201) {
                    swal('Upload successful', data.message, 'success').then(() => window.location.href = "/");
                    setValues({
                        name: '',
                        assignment: [],
                        classroom: []
                    })
                    setErrors({
                        name: false,
                        assignment: false,
                        classroom: false
                    });
                    setFileName('');
                    setClassroomChecked('');
                }
            }
            else
                setErrors({ ...errors, name: !values.name, assignment: !fileName, classroom: values.classroom.length === 0 });
        }
        catch (err) {
            swal("Info", err.response.data.message, "info");
            setButtonSpinner(false);
        }
    }

    const openFileSeletor = () => {
        document.getElementById('file').click();
    };


    useEffect(() => {
        const fetchClassrooms = async () => {
            setSpinner(true);
            const response = await fetchClassroomsService();
            const data = await response.data;
            setClassrooms(data?.data?.courses);
            setSpinner(false);
        }
        fetchClassrooms();
        // eslint-disable-next-line
    }, [])

    return (
        <Page title="Classrooms" disabled={buttonSpinner}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Upload Assignment
                    </Typography>
                </Stack>

                {spinner ? <Grid item xs={12} sm={12} md={12} className={classes.spinnerGrid}>
                    <CircularProgress />
                </Grid> :
                    <Grid item xs={12} sm={12} md={12} className={classes.assignmentGrid}>
                        <TextField
                            id="outlined-adornment-name-registration"
                            type="text"
                            disabled={buttonSpinner}
                            className={classes.fullWidthTextField}
                            value={values.name}
                            onChange={(e) => handleChange(e, 'name')}
                            label="Assignment Name"
                            color="primary"
                            autoComplete="off"
                            error={errors.name && !values.name}
                            helperText={(errors.name && !values.name) ? 'Assignment Name is required.' : null}
                        />
                        <div
                            className={classes.uploadAssignmentGrid}
                        >
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                name="file"
                                id="file"
                                className="file"
                                onChange={(e) => {
                                    handleChange(e, "docs")
                                    e.target.value = '';
                                }}
                                accept="application/pdf, image/jpeg, image/jpg, image/png, application/msword,"
                            />
                            <TextField
                                id="outlined-adornment-name-registration"
                                type="text"
                                className={classes.eightyWidthTextField}
                                value={fileName}
                                label="Attachment"
                                color="primary"
                                disabled
                                autoComplete="off"
                                error={errors.assignment}
                                helperText={errors.assignment ? 'Please select an assignment.' : null}
                            />
                            <div>
                                {fileName === '' ? (
                                    <Button variant="contained" onClick={openFileSeletor}>
                                        <AttachFileOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={buttonSpinner}
                                        variant="contained"
                                        onClick={() => {
                                            if (buttonSpinner)
                                                return;
                                            setFileName('');
                                            setValues({ ...values, assignment: '' })
                                        }}
                                        color="error"
                                    >
                                        <CancelOutlinedIcon />
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div
                            className={classes.uploadAssignmentGrid}
                        >
                            {classrooms ? <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Classrooms</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    {
                                        classrooms?.map((classroom) =>
                                            <FormControlLabel control={<Radio checked={classroom.id === classroomChecked} disabled={buttonSpinner} onChange={() => setClassroomChecked(classroom.id)} />} label={classroom.name} />
                                        )
                                    }
                                    <p className={(errors.classroom && classroomChecked.length === 0) ? classes.visibleHelperText : classes.invisibleHelperText}>Please select any classroom.</p>
                                </RadioGroup>
                            </FormControl> :
                                <p><Grid item xs={12} sm={12} md={12} mb={3} mt={3}>
                                    <Typography className={classes.notFoundHeading}>No Classrooms added.
                                    </Typography>
                                </Grid></p>
                            }
                        </div>
                        {buttonSpinner ? <CircularProgress /> : <Button variant="contained" onClick={onSubmit} className={classes.roundButton} startIcon={<Iconify icon="eva:plus-fill" />}>
                            Share
                        </Button>}
                    </Grid>
                }
            </Container>
        </Page>
    );
}
