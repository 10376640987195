import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { localStorageEnums } from './constants/LocalStorageEnums';
import { Roles } from './constants/Role';
import DashboardLayout from './layouts/dashboard';
import AssigmentsTeacher from './pages/AssignmentPageForTeacher';
import AssigmentPerStudent from './pages/AssignmentPerStudent';
import AssignmentUpload from './pages/UploadAssignment';
import WBPage from './pages/WBPage';

import { SuspenseFallback } from './suspenseFallback';

const User = React.lazy(() => import('./pages/User'));

const Classroom = React.lazy(() => import('./pages/Classroom'));

const StudentsTeachers = React.lazy(() => import('./pages/StudentsTeachers'));

const ClassroomDetails = React.lazy(() => import('./pages/ClassroomDetails'));

// const StudentVideos = React.lazy(() => import('./pages/StudentVideos'));

// const VideosByCategory = React.lazy(() => import('./pages/VideoByCategory'));

const Category = React.lazy(() => import('./pages/Category'));

const Login = React.lazy(() => import('./pages/Login'));

const Assignments = React.lazy(() => import('./pages/Assignments'));

const NotFound = React.lazy(() => import('./pages/Page404'));

const Register = React.lazy(() => import('./pages/Register'));

// const DashboardApp = React.lazy(() => import('./pages/DashboardApp'));

// const Videos = React.lazy(() => import('./pages/Videos'));

const Teachers = React.lazy(() => import('./pages/Teachers'));

const Principals = React.lazy(() => import('./pages/Principals'));

const Students = React.lazy(() => import('./pages/Students'));

const MyProfile = React.lazy(() => import('./pages/MyProfile'));


export default function Router() {

  const authorized = window.localStorage.getItem(localStorageEnums.TOKEN);

  const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
  return useRoutes([
    {
      path: '/dashboard',
     element: window.localStorage.getItem(localStorageEnums.TOKEN) ? (
        <DashboardLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: 'app', element: !authorized ? <Navigate to="/login" replace /> : <Navigate to={userData?.role === Roles.Student ? "/dashboard/assignments" : userData?.role === Roles.Teacher ? "/dashboard/classrooms" : userData?.role === Roles.Principal ? "/dashboard/students" : userData?.role === Roles.SuperAdmin ? "/dashboard/principals" : "/login"} /> },
        // { path: 'videos', element: <SuspenseFallback>{ userData?.role === Roles.Student || userData?.role === Roles.Teacher  ? <StudentVideos />: <Videos />}</SuspenseFallback> },
        // { path: 'videos/:id', element: <SuspenseFallback><VideosByCategory /></SuspenseFallback> },
        { path: 'teachers', element: <SuspenseFallback>{userData?.role === Roles.Principal ?<Teachers /> : <Navigate to="*"/>}</SuspenseFallback> },
        { path: 'students', element: <SuspenseFallback>{userData?.role === Roles.Principal ? <Students /> : <Navigate to="*"/>}</SuspenseFallback> },
        { path: 'organization/:org/:type', element: <SuspenseFallback><StudentsTeachers /></SuspenseFallback> },
        { path: 'principals', element: <SuspenseFallback>{userData?.role === Roles.SuperAdmin ? <Principals /> : <Navigate to="*"/>}</SuspenseFallback> },
        { path: 'classrooms', element: <SuspenseFallback>{userData?.role === Roles.Teacher ?<Classroom />: <Navigate to="*"/>}</SuspenseFallback> },
        { path: 'classroom/:id/:token/:name/*', element: <SuspenseFallback>{userData?.role === Roles.Teacher ? <ClassroomDetails /> : <NotFound/>}</SuspenseFallback> },
        { path: 'assignments', element: <SuspenseFallback>{userData?.role === Roles.Student ? <Assignments />: <Navigate to="*"/>}</SuspenseFallback> },
        { path: 'assignments/:classroomId/:assignmentId', element: <SuspenseFallback>{userData?.role === Roles.Teacher ?<AssigmentsTeacher /> : <Navigate to="*"/>}</SuspenseFallback> },
        { path: 'student-assignments/:classroomId/:studentId/:name/*', element: <SuspenseFallback>{userData?.role === Roles.Teacher ?<AssigmentPerStudent /> : <NotFound/>}</SuspenseFallback> },
        { path: 'category', element: <SuspenseFallback><Category /></SuspenseFallback> },
        { path: 'myprofile', element: <SuspenseFallback><MyProfile /></SuspenseFallback> },
        { path: 'upload-assignment', element: <SuspenseFallback>{userData?.role === Roles.Teacher ? <AssignmentUpload /> : <Navigate to="*"/>}</SuspenseFallback> },
        { path: 'user', element: <SuspenseFallback><User /></SuspenseFallback> }
      ],
    },
    {
      path: '/',
      children: [
        {
          path: '/', element: <Navigate to={userData?.role === Roles.Student ? "/dashboard/assignments" : userData?.role === Roles.Teacher ? "/dashboard/classrooms" : userData?.role === Roles.Principal ? "/dashboard/students" : userData?.role === Roles.SuperAdmin ? "/dashboard/principals"  : "/dashboard/app"}/> },
        { path: 'login', element: !authorized ? <Login /> : <Navigate to="/dashboard/app" /> },
        { path: 'register', element: !authorized ? <Register /> : <Navigate to="/dashboard/app" /> },
        { path: 'whiteboard/:classroomId/:assignmentId', element: <SuspenseFallback><WBPage /></SuspenseFallback> },
        { path: 'whiteboard/:classroomId/:assignmentId/:studentId', element: <SuspenseFallback><WBPage /></SuspenseFallback> },
        { path: '404', element: <SuspenseFallback><NotFound /></SuspenseFallback> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
