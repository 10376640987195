import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Box } from '@mui/material';
import { useStyles } from 'src/styles/components/styles';


Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const classes = useStyles();
  const logo = (
    <Box sx={{ ...sx }} className={classes.logoIcon}>
     <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">  <image id="image0" width="40" height="40" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABAAAAAQACAMAAABIw9uxAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAZlBMVEUPADkrGjw6Jz5lTkJ0
W0SQdUefgkmuj0rKqU3Ztk/ow1D20FK8nEyCaEVWQUFIND8dDTssGjyCaEZmTkJXQUGRdUc7Jz5l
TkNJND/LqU1XQUDKqU6fgkiggkmDaEWggkitj0r///+7DxcCAAAAAWJLR0QhxGwNFgAAAAlwSFlz
AAALEwAACxMBAJqcGAAAAAd0SU1FB+YIHQkpFbiA1jMAACyLSURBVHja7d3bQtvItoXhBGyMD7Tl
QGi6yUrv93/KHU6JAYMPksYYVfV/V71uVpDkWaqac1bpyxcAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACk+3p2Pple
zC7n88Xy2Xy+mk2v/lp37r8NwGi+nl9dXP6O+l0Wq+lk7f4zAQzs6/n089Dftpr+tXH/wQCGcTaZ
HRz7v11OmQkApesmF8cH/8t6YHbORAAo1vrb6tTg/70aYAwASrSenvzqf+3iL/elADjK+ttA0f9o
fsE0AChF13/m/34aQEoQKMF6+Oh/mgacu68MwOe6Qaf+DAFAQQbL+zEEAKUZa+7PEADEu1aEP0MA
kOh6Lgr/BxQFgSCjZv52+ta5rxnAE+nb/xnrACDCjSH8H7AOAOwkmX8mAUCijS/8HycBnfsGAO3q
vlvD/2ESwDIAMJmoU/+7fHPfBaBJxsX/KzMmAYCaf/b/G8sAQCxi9v9iceO+HUBLzLn/90gEADJR
r/8n3933BGjEbdrr/9Gsc98XoAUTd6h/4JJUIDC2uNX/HxQDgJEFrv4ZAQCN4Nc/IwAwsnXy658R
ABhVTusfIwAgtrl0B/dBLjv3jQIq9Hf89P/Zyn2ngPqUMP1/Rk8gMKxCpv/P2BcADGltOvTzVJwU
CAwntff3Q4sz9y0DahF08MfB5p37rgF1KGv5/4JSADCE28KW/y9IBAL9FVP9f+fOfeuA4n1zh/Hp
SAMAPRWY/vtj5r57QNG68L2/+0zcNxAoWJnp/y0LNgYCp9oUmv7fQi0QOFGp5b9XaAkGTnJbbPlv
26Jz30egRH+7Q3cg7AwGjldL/NMOBByvuN1/HyMPCByp4Pa/98gDAkepKv7pCAaOUlf8L5dX7hsK
FKS2+KcUCByuuvhnCgAcrML4ZwoAHKjG+GcKABymzvhnCgAcotL4ZwoAHKDa+GcKAOxVb/zTDgjs
U3P8syMA+FzV8c+mQOBTlcc/JwQDn6g9/kkDAh+rPv6pBAIfaiD+SQMCH2gh/kkDAru1Ef/Lqfs+
A4kaif/lwn2jgUCtxD9rAOC9duKfNQDwVkPxzxoAeKOl+GcNALzWVvyzBgC2NRb/y7n7hgNBWov/
5fKr+5YDMdqL/+XEfc+BFA3GP3uCgWctxj+FQOBJk/FPIRB41Gj8kwQAvrQb/yQBgIbjnyQA0HD8
0wkAtBz/fCAErWs6/tkOgMa1Hf/Lf9z3H3BqPP7JAqJprcc/WUA0beKOPzuygGjZtTsA3cgComk3
C3cIetELiLbdtj0CcCoQGnc7dwehlfv2A2abpkcAygBoXdMjwL/uuw+4tTwCcCQA0PAIQB0Q+NLd
uwPR5Yf71gMBmh0B2A4EPPifOxQ9aAQAHjU6ArhvOxDipzsWLWgEAJ40OQIwAADPWhwB+DgI8KLB
EYATAYDf2jsihAEA+KO5I0Ku3HccSGI8ImQ+N/zbDADANssRIYvV5Lb79Y93Nz/E/zK9wMAr+iNC
VpPuzz9/NmMAAIzEmwNXd85/nwEAeEMZgatdhXhhOXLlvtlAHNkIsPjgQA5dMYIBAHhHNAJ87z76
A2QjAAMA8J7igID5Z2243xkAAJ/xR4CPX/+P/76oGskAAOw07gEB8327cK40AwAnggC7jTkCrDb7
/vWOAQCwGq8ad8hp3PcMAIDVSCPA3un/I00akAEA+NAoI8Dl3un/I00lkCQg8LERRoDvB/7TNwwA
gNvgR4Qc/DGuNQMAYDfsTHxx+Bl8DABAgCGPCJkftvx/xAAAJBjuiJBVd8Q/q8kBzNx3F0g31BEh
/x31r2oOKOU8AGCfYTYH/jzuH9X0ATAAAHsNMQIcnP5/tpIMAFP3rQUK0HsEWNwc+09qtgNyKjBw
gJ4jwPz26H9QEv9Hz0uANvU6IOCY8t8zTRGALwMBh+kxApwQ/18uNAMAHwcFDnTqAQEH7v55TXQu
KQMAcKjTRoDL7oR/6kwT/8uv7nsKlOOUzYHHtf+8UB0K2rlvKVCQ40eA0+JftQJYum8oUJRjR4Aj
2/9eqFYAHAgEHOW4EeDE+FfVANgMCBzpmE06p8a/bAXAZkDgSIcfEXJy/Mu+DMZWAOBYhx4RcnL8
izYCLekEBk5w2BEhpweXKgW4XP7rvpVAgQ45IuT69P97VQpwuTxz30mgRPs3B/aIf9FGwAed+0YC
Rdo3AvSIf+EEYOG+jUChPh8B+sT/MAeQHYQ2AOBEn8Tpok/8f/kmi3+qgMDJPjwgYHH08T/bhBMA
qoDA6T4YAfrFv3ICwGkAQB+7DgjoGf/KCQBFAKCX9yNAz/iXTgAoAgD9vN0c2Df+pRMAigBAT69H
gL7xL+wBWFIEAPrbHgF6x7+wCXDJTgBgAH9GgN7xr50AsBMAGMDLESH94187ASAHCAzheqD4/zKT
DgDkAIFBPBwRMkD8yw4CesKHQYFh3C4GiH/ZSYDP6AMEBnI7QPyLJwD0AQJBpD1Av/zjvmAAf2hL
gLQBAUm0JcAlbUBAknv1ANC5rxjAC3UGkBQAkKMTZwBJAQBBlMcAPKELAEghzwCyEQDIod0E8IDv
AgMp5BnA5fLcfc0AnugzgMvlxn3RAJ7oM4BsBQZS6DOAfBIESOFYALACAEKoNwE9oA0QyDDpH87H
YwUARNgsHAMAKwAggiMBwAoAyPDdEf90AQERDC2AD1gBAAHUxwA+owsISHDpmQCwAgACGFqAH7AT
GAiw9sT/8of7wgG4EgCcBQQkcLQAP5i7LxyApwX4ASlAwM7TAvyAJgDAzpUAIAUI+JkqgL+cuS8d
aJ6rAkgXIOBnqwCSAgT8XBVAaoCAn2kP4AOOAgLMjAsAaoCA28oX/9QAATNfBZAJAODm+AwIEwAg
hDEBwD5AwMy5AKAJCPDytQAuaQICzCzfAXxBExDg5fkMABMAIIGxBZAJAGDmbAFkAgCY+fYAMQEA
3KwLACYAgJV3AcAEALCyLgCYAABW3gUAEwDAybsAYAIAWHkXAEwAACfvAoAJAOBkXgAwAQCcvAsA
zgEAnMwLAM4BAIzMCwBOAgSczAsATgIEjMwLACYAgJF7AdD8BODr1/X6/Px8cvVi8ut/rdfrr537
L0MLzAuARaMTgK9n55PpxWq++PTmXK5m06u/1l/dfy2q5V4A/HTfALXu7Hw6+zzud7hcTSfrzv23
ozruBUBTPUBfH0K/z91aPAwD7qtATcwLgGaagH/F/urYt/5HVtO/ztzXgzq4FwBNTADOJheDz7Pm
s3MGAfRl/Q7Ag+onAF8ns6Fe/O8HgYvzRjOoGIj1OwDL2kuA3fnFaMH/YjVhIoBTOb8E/KjiF9jX
yUp0E+cX5AVxEvcCoNoJwHqqvbXzC+YBOJrzS8CPP9s6JwDr6egT/103k3wAjmNfANTYA+SJ/iez
v9xXj5K4FwD1lQDPvvmi/+mWfmMagANNzPFfWwmwk2X9PkVGEAfZmF9WlZ0DtB6v3H/0ja1sZMUo
3D3ANZUAO/fU/405QwD2cPcAV1QCDHr5/8YQgE+5NwFWUwIMWfnvuMEMAfiYuwd4OXHfgUGkzf1f
YQjAR+wtAFWUANcz923cd5epCGAn9wKghhLgOnTu/8pFJSstDMrdA1xBBvC6hPB/8M19pxDH3gJQ
egmwm9inUIcjFYA37C0A3913oJfozN8urAOwzd4CUHQJsLjw/2VRR80Fg7AfA1ZyBrDE8H+wKnnM
xaDsGcCCS4DX9sHzVEwC8MTeArAs9vCadbHh/4BJAB7cu3+IpZYAi6j7f4ZyAAIygIV+C/C29PB/
UHb1BQOwbwIq8xywzr53YhhF118wAHsLQIkZwFJT/zssWAY0zZ8BLPAHeGOfNQ2J1uCW2X/K5WUA
i8/9vTVjGdAsewawuE0AtSz+t5EIaJU/A1haGnpSzeJ/2+LOfV9h4c8AlvXuqW72/xttgS0iA3iU
Gmf/v5EKbJB9AVBUCfCmytn/b6UtxtCbPQNY0gJgU+3s/8Wsc99jSPkzgDfuW3C4OpN/r1127rsM
JfuKtpwe4Ppf/49KmpGhL3sGsJwWIPuBCSqMAA1xlwCL+bHdXrrjkoeCwdkzgKX81Jp5/T9iBGiF
OwNYSAKgpdf/I0aANrjfa4UkACbueNRjBGiBuwRYxq+skeT/G1QDG+DOABax+aSF2v8ujADVc5cA
S0gAdOlf+h3PhfveY2T33h9YCVsAyj7xuyf2BdTNXQIsIAFgb5P0Ym9g1cwvt/wFwKa14t87nA9Q
MXMJcOW+/r3+bjT7t62INC1OYS4Bxn8HpOpzPw4W/5hwKnMJMH1y6W6RSDHv3E8CozCXANMXAK0W
/99Lf1I4jXcCED6zZPq/hWJgjcwlwOwFANn/V4o6shWH8a5ws6eVlR/7ebTw6RpOYJ4ARP+i3Dsk
85AIrI53ApDcAtQ1ufVvD3YFVMb7kkveA3BL9W+X7JwNjmSucQcvAGj+2400QFW8JcDgBQDL/4+Q
BqiItwcodwHQ8Nb//egGqId3AhC7vYTm30/FPjccyTsBiD0GdM3y/1MsAmphrXPFHgPa4MG/R5q5
HxEG4e0BSm0rpfl/v4I+4oqPWVe6l+6r34303yEWnfs5oT/vBCBzAcDmn8OwCKiAdQKQ2QJA99+h
WAQUzzoByGwBIP1/MCoBxbO+7CIzgKT/j0A7UOGsva6RLQB0/x6FdqCieZvdEjOAlP+OE1rGwWGs
r7vADCCb/4/GxuCCWScAgRlAyn/HoxmgYNZdQHkZQHb/nILTgYpl3QWUlwEk/k9DHrBU1glAXAbw
lvL/abKPdMaHrBOAuAwg7T8ny1vM4RDOCUBcBvBvdxQVjH7AIlknAGkvDeK/jyv348MJnBOAtPYR
2v96oRRYIOsEICwDSPz3xBSgPM4JQFgJkPjvi88EFMc5AQg7B5D4749uoNI4JwBZ7ePE/xCyxnTs
Y50AuC/+FeJ/EEwByuKcAESVAIn/gTAFKIlzAhCVAST+h8IUoCTOCUDSq4L4H07Sc8XnmAA8If4H
xBSgHMYJQFIJkPgfVNCTxaecG9+DSoDE/7Cm7geKAxl/+UElQOJ/YOwIKIVxApBTAiT+B8eOgDIY
PwaUswuQz38MjylAGYwTgJg8Efv/x8AUoATGCUBMCfDWHSp1Csrw4ENMADj/cywcEJyPCQDnf4+G
A4Lz+T5+ldIDRPyPhylAurXvxxHSA0T8j4hmoHS+LuCQDFHH9/9GRCUwnHEbUEgPkPVzSCeZry6m
V9fPJldX04vZ6j41jUklMFvzE4CiGgAXs+n1h4mT27vrq9m9+0986x/3A8Znmp8AlBP/i4vrg5Km
tzdTX153B9KAyXy//4wCUSkNgKufx8XR3SRmEJi5nzE+4UuAR5QAy2gAWk26E66tu/kRcXWkAYP5
moAieoBKKAAufp6dfoE3P9x//jKm2osd2p4AdPnxv+q7gt5c2y8yY7GHHXxNQBETgJk7NPZY/Bxk
mLx2pwNIA6b6P9tvImECEF4AWPzshrrSO+8sgG7AUL4a4E/3pX9JPwFkflLi70PWhcDC/aixm60J
KGEX0CYiRf7RDRq+SeKn8XJYA0TyhUDABCC5ADDg5D/jimkFiGSrASY0AecmAMcJ/we2SQCtAJFs
b4SAJuDcBOD3bryrvnU98oAnjrdsNcCACUBsArB33f9zm3vTZbkfON6z1QD9rwPjISifmt+Mfun/
81xZ537keMtWA/RPAFILAGPO/n/zJAJoB45jqwHaJwChBYDVrebyLSMAa4A4rijwTwAijwBb6N6R
lhGgcz91vGarAdonAN9dV/6ZlbI1ypEHsD92vObaIGKfACQWAIWv/0f3+ktkDZDFlgJ0vwkSC4DS
1//j09ev/+gFyuJKAbonAIHfAFS//k23gf0AUVwpQPPPILAAMBcl/1/TT4QiToDAM1cK0LwSDIx/
Se1/B3kOiD3BSVwpQPMEIK4AuBi/9e8D+mYo1gA5XClA8wQgrgAoz/5tkS8C+ERQDlckeF8CcQXA
79bboZ4F8omgHKalsHcCcOO56A8tzHPiO/UFd97rxW+uzXDWX3zaDqBL+7Fo6jNR3C0geGFqArBO
ANIKAK7s//YtEV8yhcAQrhSgcwIQ9g0QR/PPe+LPBlEIDGFqArAmgbKOAPQ0/7yjfhNQCMxgagJw
LgGzCgD+5f8z8RQgYtoD0wrAuQsgaweQt/q3TVwIYEdghCvPz944AcjaAZT0HtROBtkRGMGTDjNO
AKIKAO7q/2vi3oioa2+VqQnANwGIiv+Ej6Jt0zZH0A0cwNMEYJwAuD+OvS0m/fdCux4kCRDA8z70
TQCSdgD917mf/lvaNCBJAD/PCsA3AUgqAAZ8E/Ud7fyIJICdZwVgmwAk7QBKSv//pl0DRN6CtlhW
ALYJQNAOIN/ZH5/fIelNIAng5nkjuua+QQWAkO7f96RrALYDuFlWAK7aV5dzBFha+e8P7Rrgq/ty
W2eZErv2geYUAOLKf39o6wCcCeDlWQGYfv05BYDLzv3gPyF9J3AmgJdlBWB66Dk7gPLK/9ukWwLd
H4ZpnSUp5pkA5OwAytn9t5P2eIjOfblNs3QBeSYAOQWAxPafbZ30btAK5GRZAVgeec4RYOnxL/5W
MK1ATo6o8PR+xBwBlh//2mIJWUCjM0cEWAo/MQWAEl540tIQWUAjR13c8sBj4v/a/cgPoU0CdO7L
bdi9IQQcEwDXh0/eKSL+xb8LsoA2jtNA553hOkN2AC0KiX/tzLCERVGlHN8DMOR8UgqAi9TtP+9I
fxhkAW3+zxAFhiagkB1A5cS/dmrIR4JtDDNjw3AfsgOooPjXlofZEeziSI3pMz4hBYCi4l+7HYAd
wSaGV6O+CSjkCLCy4l+7b+pf99W26l4fB/IaYEgBoLD4154JQBnAw1AElDcBhRQASot/bSsQZQAP
QxFQPdaH7AAqLv61s0PKAB6GnYDqGmDGDqAC41+aH6IM4KF/O6rnehkFgBLjXzs9pAzgYNgJKK4B
hhwBVkr/7yvSBBFlAAd9CkCcAgw5AqzI+Nc2iXEysIO+D1j7nEMKAIXGv/TzIFP3xTZJHh/afYAh
BYBia9zKLODMfbEt0qcAtClA7UduP1LA+V8fUHZQUgc00KcApDXAjAJAufEvbQWiDmgg7wKQbgMg
/ntTLqE698U26F4dDcoUYMYOoKLjX7shUMd9W1Noz338ZdHpLi5jB1D493/2cRwXJeC+rSnkZwEI
U4AZBcD/3I+4711038BxuG9rCu1H4JfSLsCII8Au3U+4t4hp1ODcdzWFukom7AKMOAIs+vvf+339
a3IRMY8anPvOplAP77oUYEQBYO75APIAurPz6arOl/8j9/0NIW8DkgVExA6gMuO/W9f62t/ivskh
1HUyWbNnxA6gAuP/rIHYf+S+0SHU62TVCiCiAFDYAQDdX1XP+d9w3+0Q6hxgp7msjB1ABX3srjuf
RtRMdNx3PIR4xFft9og4AqyUDcC/gj9ivNRy3/UM6j5A0YbviAJAEQ3A3bq1N/8L953PoO4D1Jz5
RPwf5uukoTX/W+6bn0FdK+sUF+X40tk78RsA1i3O+7e4738GcRFAkgKI2AGk//TZMbrzi4SbZOV+
BhnERQDFRqCIAqD21LPjdC1P/P9wP4YM4l+CIgWQkNPKbQD6Ff3umxPC/SQiqIsAZ+NfUsIOoNT4
J/q3uB9GBHG6THDkW0IBILMBsFsz89/mfh4RxDsBxs+MRewASmwAWk+J/tfcTySC+DSQ0duAInYA
5TUAdN8SEqNh3A8lgvijQGPnACMKAGnx/2vq774lkdzPJYL4pzFyDjBiB1DYCYBn35j67+Z+MhHE
ETPy1STsAMo6AYyX/8fczyaC9paP3AeYUABIKgB2vPw/4348CcQHPo97GhDx/8qaZt/PuR9QAnEb
wNWY1xLxDaCYBgDm/nu5H1ECcdCMeRxYxA6gkE+AU/U7hPspJRC3zYx4QhYFwN9Y+h/G/ZwSiPuA
vo52IV3CDqCIEwAI/0O5n1QC8caZ8S5E/onzHRI+AbZJuBGFcD+rBNpGwPE+CkYB4BHhfwz300qg
TRWPthUoYQeQP/4J/+O4n1cC7QAwVhtAxA4gdwGQ8D+W+YFF0KbOR9oLGFEAMBcAu4RjUArjfWIZ
tKEzTh9QRPx7C4Bk/k9hfWQhtHd8nLdkQgFQ9sXTXQj/0zifWQrtHR+lETBh7ms9Avg6YQZUIuMz
i6G942M0ArZeAKTn/2S+h5ZDe8dHGAASCoALX/xvCP/T2Z5aDvGh4MMPABEFQFsBgMV/L67HFkR8
HMDgWwHaLgDcJFx9wVzPLUjhA0BE/Lt2ADH778v04JKUPQBE7AA0FQC6hORn4SwPLkvZA0BC76up
ALBOmPuUzvLksoiTgMMOAAnvQE8BYJNw+nH5HI8ujfaODzoAJMS/pwAwIfc/CMezS6O940MOABFH
gDoKACT/hmJ4eHG0d3zAASDiCFDHDgBe/4MxPL042js+XCNQRAHQUADg9T8g+dMLpH2dDDYAZMS/
PgHI639I8scXSBtIQ30bOKIBQH8EEK//YamfXyLtADDUduCEBgB9AYDX/8DUDzDRvfSODxQzCScA
yDuAO2r/QxM/wUjaSeUwZwJGNACovwFA69/wxI8wkva7AD+G+JMj4l+dAIyY9NRG+wgz/ZDe8SG+
CxAR/+IOYLJ/o5A+w1DaN8sAXwaKiH9xAvCG7N8opA8xlPjjoL3/3oz413YAM/0fifQphrrW3vK+
vcAZ8S/tAN5E9DxUSfkYU4kHgJ6NAH+7fzKPpB3Aa6b/oxE+xlhr7S3v92mgjPiXJgAzpjyVEj7H
WOIjgXpNniM2AC+XN7qnQ/PPqHQPMpf4SKA+ZYDbjMmwMAEYseWpYronGUwcVd3Jf2hI/A/Sy3QY
qn8j0z3KYOKXzMlZwIgDQKQJQJb/Y5M9ymTaXuCTdwOEzIZ1HcBdxI7HuqmeZTRtL/CpSYCQ+B/l
66a7L5jq//hUDzOa+uOaJ7UCpcS/LAGYcsF100VZMHEn0Eld9CnhIDsCgO4fCWGY5ToT3/QTNgSm
xL8sAZjwyfMWKOMslrgR4IRCYEz8qxKApP9FtJGWSh1ex64BUuJflgBk85+KOtQyqc+aOHINEBP/
oiMAKP/p6IMtkfyFc9SbNKT/T1YAyDjwvBGOcMsjzzgdMwWI6YYVHQEQM99pgiXe4tzJ7/vhybSY
dLioAED8S5kiLoy8DLC8OPRPi0mHiwoAxL+WLeay6H91Zwf9XTnpMNERILfEv5Yz6oKItwMtD8wC
BHXDa44Aicl3NsMadjkMdecDNgUHfQlHUwAg/uXMgZfCcNLW/jl1TPqP+K+XO/JCiI8FfLRnEZD0
JRxNAZD4N3BHXgrHZPvTjXUx1f+lqgBI/Du4Ay+FPgv4y7cP/5yk17+oAEj8W7gDL4Vnvf3BCNB9
iwqGW8UDIP493IGXQt8L+GjXKiAs/DU7gIh/E3fgxTD9AN9Nr9PCX1MACDnvuEHuuIthW3RfrP/8
Ed0kae3/SHIEGP2/Nu64iyH+Rvi2+fR8vV6fTy4Cw+BSce+Jfx933MUwJQHCSQoAxL+RO+5ysAp9
T7IDqCP+jdxhlyNu9R1AsgMoZ8NTi9xhlyOo8z6FpAAQs+G5Te6wy+HYDpBNEv8xB540yh12QViK
vnbC50uOR/ybuaMuCGfRvyIpAPztvsrmuaMuCIXAbZL4pwHYzh11Sfg1blHsAKIBwM8ddEl+uB9G
EMUOIBoAAriDLglrgN8kBYCZ+yrBALCtYw3wTHIEGAWABO6gi8Ia4InkCDAaryK4Yy4Ka4BHmgKA
+yrxyB1zWVgDLEU7gCgAhHCHXBZ6gZaiHUDEfwh3yGVhDSAqADDSfmqu4w65MOwJlhwBRgLwc1/d
cdAs48FgGTRHgLmvMp07DNrVuR+9GUeAJVi4w6Bhba8BNN8A4giQPf5xR0HD2k4DSr4BVGwH4GI1
mUr+IclJDNjt3v0zM5J8A6jQBMB8etepPiH5wx0ELWs4Qa35BlCJCYDVz7unv16zQpy6g6Bl7e4I
khQAC0wArCbd779e8+O4cgdB01qtBEoKgMVNsLajX1YkOnfHQNMarQRqCgBlfQR0/vPNTVlr/t07
dwy0TZPoCaOJ/5K2ACym7+NQNH85c4dA25qsBEoKgAVVAFc33Y6/X7SFoTvytmJYDTYDSQqAxZwB
sPjZOX8ZNAKatTcFkBQASzkEdPXxElyTwaAR0K21KYCmAFjEHuBdK//fRPlhGgHdGpsCiH5w1+7r
3O/Duf8TURGARkC7pqYAkiNAS2gBXJ3vuROiIgB9QHYtTQFEBcD4FsDV/uq7aA1DH5BfO1MAyRGg
v9y4L/RzB4S/7FdBH5BfO1MA0a8tuwJwUPjLDo2mDyhAK98I0TQAZFcADgz/L2eiv8f2o8cfZTWt
n0zTABB9CMCh4S9bxdAHFKGJTYGq+M/dAzA/IuMmmsXQBhChhXMB/lPdzNQ9AHvq/m+IcoCSL7Ni
rwL6Vnq67ES3MnU99f24GyC6CtoAQtReClQ1AKS2AKyOvH5VHkOVl8UepexdO5Eu/iPnUvOjP4Go
6mSgDSBFcumqN138R2YAj1r8P1GlhWkDSJHdvNLPQnMCyIPADODqlKtXLQl1P3DsUXE/oOQb4I/y
WgAWp62yRTlATgMIMnP/VsciTDTFZQCPTf49U/UB0gYQpNZmAFkDkGwP/cFOfP3rcoB8FSRJ+B62
EwnjPy0DeOLr/4suJUwVMEqNiwBl/GeVAE9+/X/R5QCpAkapsBIgOgHwSdTtu+xT+lT9kVQBs1RX
CZBtAHgQVQI8svP3NVkuQ/l4cIDK2oGkW02SNgEs+lU+VR81pAoY59790x2SbAPQo6ASYK/p/xfd
B+PYCxgn6TXWlzb+g3qAeic+VMkM9gLmycpk96GN/5wJQJ/s/xNVG9DyX+kTwkFqSQOIvgDwImYC
MO+/8UH2EqAKmOje/RMehHAD4KOUCUDf5b/0WjrtM8JB8r9qcwB1/KdMAAbpe1D9ACgCZKqgG0Ad
/ykTgEH6HmWDGVuBQqnKwKORx3/GBGBxPcjFyPaEsBUoVeGJwCHWwceJmAAMde6J7GIoAsQq+oxQ
cf3vS8gEYLBpjywHxE6AWCVvC9LHf8QEYLD4141m8ueEg5VbClh1+pvlvublkMOerAuAIkCy20J7
gqX7/54FTAAGnPaoNgKwEyBbmcVAR/wHTAD+64a7HNncj+OAspW4K0B5/s9v/gnAkMOebCMAjcDp
ymsHsMS/fwIw6LRH99Q7x9PCEX66f9lH8swp7ePksMseWQV4YXlaOEZRI8BAfXBHc9dLhm2o7Qr9
uzGKgkaAAbbBnsSdKhm47UF3NjyngZSgmBFA3v7/wjwBGPrYA11Gk0bgIhQyAhjaf56YPwY0+Lin
G89cIzaOU8QIID3+/xVvDXDw+NcVAckBlqKAEcBS/nvkrQEuBn+LXsn+dnKAxbDXufZYGDtKdAGz
y/AXfi/728kBlsOd6P6cLf33wJoCHL7vQTihoQ+wIMk7g4Zsgz+adWgcYeEjvB7nY8OxcncHe3eU
OA9OGWMzne562Atclk3mp8Pn3omkMwU4xncPhNfDXuDSJBYDbNX/Z8YU4PAFgC/SFQB7gYuTtwyw
/4iMd6Tf538/IFzRcB5ggbImAa7m/z+MXYCjdD4IVwC0ARXpLmgS8L1z3w1jF+DlKNcjXAHQBlSo
lEmAOfv3qLMVR0dqfRCuAGgDKlVGJiDg9e9sAhglASCtaQSM3zjRtX0ISHj9fzE2AYy09UE5oHXu
h4fTbX64fvnPv//OfQeeboPr+ucjXZBwQKMNqGwbYwfcKmUfuW0FMNINUA5ofBa0dK51QMjs/4Fr
EBxr77Oyq4nTgMrnGAIWIbP/B64VwFgLAGlXU8osDn2oh4Ck8PetAMaKHWVXEymASkiHgB+d+3Jf
Ma0ARjv8SNnV9MP98DCUG1EcLKZhs0bTCmC0BYC0q4kUQEUURcGsyf8j0wpgtGFQej1xTxN9bEZe
Cczzwl/4Ee1Xxps73wuvghRAde5+jDYGrHIKf9ss+wDGO/5Qdxz4ki6AOt38GCEmLhNf/g88O4HP
R7se6cbGzCEdvQ08Bix+5v5SLDuBR8sAijc2uh8exnM3vR/mR7IKjv4vprOAxtkE+ECaAuQsgLpt
bn7c9/uF3E+vO/dV7LlGZcC8GLF6Lh3P7Ce5YXSbm+nqlFnl4n46ue3cf/1+liLgeJ0Q2owGxwE2
4vbmanZ/6K9ifn8xuQlr9vmYowg44gRAejnjZTKQaHN3fXWxWu2eZC7m97OLq+ubTef+M4/jKAKO
NzpqFzT0Abdqs7n75frRr/+43RTzwn/LUQQcMW60JQ36gFE6x/eSa5kA0AeM4hl2Ao44AfgmvRCK
gCieIQUw4nJJ29NAERClkzbOPxlxAiAuaVIEROkMKYAR2yK1EwCKgCievgtgxIWzeAJAERDFu5cP
AONtA1Tvaoje4QEcoJPH/4jzZvEEgBUAiqdvA/o+3sWIJwCsAFA85Rc0noxXA1TvaqINEMWT5wBH
TAGqP+3gfnZAb/fqAWC8FKB6AjBzPzugL3kOcLzX5kZ9sNGI1QxAQ54DHC9xpt0FsGQjECog7wMc
LXEmP9iMFQDK910cNeOtAORHG7MCQPnUe4FHe23KJwCLzv3sgN7Ue4FHe23KjzanCwjlkxcBxuoC
0p9sTBcQyqc+DGCsLiB5CZB9AKjBjThsxvqSpv7jZqwAUAF1FXCk/bOGbxuxExgVUFcBu3EuQ/9x
Q1YAqIF4K9A/41yFvAdwvLUMoCRuAxinC8DxcdNiPwMDbBHPncc5RdvwdXO+B4AqiONmlMyZYQFA
GzCqoO4D+jrCNTgWAKwAUAV18IxwCZ1hAUATAOogPg1gjCKAupD5iCYAVEE8AIyQOtPvAVjSBIBa
iMNn+CrgxvBlU1KAqEXpX9KyJABIAaIW4q0Agw8AlgQAKUDUQvxVkKEjx9EBsCQFiGqUPQDov2r2
iBQgaiEeAIatAugPAXlCChC1EA8Ag747XfG/IAWIWqi/DNoN96d3l574JwWIeqgHgAHTZzNT/FMD
RD3UA8DVYH+5pwC4ZCMwaqIeAAaLHlMBcMlp4KiJ/MuA3TB/ty/+qQGiIvKtNMOsAXzxTw0QNZEP
AIN8G9QY/0wAUBP1d0EGeYPa8n+//HQ/MWBA+mbaedfzT+70HwHaQg0QNTGcp9czC2Dr/3lEExCq
YhgA+nXSuvp/nzEBQF0MQdSnF2BtOQDoNyYAqIwjoE7/Oogx/f+ICQAqY5lRn7gjoBN/x+wdJgCo
jfjjoE9OSwOsvcv/JRMA1OeHJZLmx4dS56z+Pxnny6aAkXo30KkjgP/1z1GAqJDluxpHjwABr3/2
AaNGZ65wWhxRC/jmLf49IwOA+qg/D7zl24F/4nXA7H9JCQB1Mr5cD1oGhIQ/EwDUyVpb/74nqrqM
yf8DJgCokje9Nv9sd/D6Iib8mQCgUvJDwd6YT3eH1noaFP1MAFArWxngj9X56zGgW09WUdG/ZAKA
WhnLAFvms6vz9Xp9fj6ZzlKyfts4CAi1Soy3NCe0LgNl8OwGKAtHAaNarmbggnAUMOplOBWsNEwA
ULG0hHsctgGjZpYzQUpCBhA1c7cCpaMHCFULaAVKRgkQlSMJ8BkygKgcnQCfoASI2tEJ8AkWAKhd
xnaATGQAUT/3BzdykQFEAygEfoQMIBrAGuADLADQBNYAO/X7kjlQCtYAO53+GWOgJKwBdrl0PxZA
hDXADiwA0Ap6gd7jHEA0o2M/wFssANCQhM/vRqECgJbcuQMuDRUANIU04Cu0AKEtpAG3sQcAjSEN
uI09AGjNlTvognx3PwxAjSnAb5wChAZRCXxGBRAt4hNBz6gAoklUAh+RAECbaAZ6MO/czwHwYApA
AgANYwpABwBaxidC2AOMhjVfCOBL4Gha470AbAFA29puByQBiNY1vSPgxn33Abe5Owp9SAAC7ZYC
6QAE2u0GWrlvPJBg02YekA5g4FGT3wmjAAg8u3dHox4FQODFrTsc9W7d9xzI0VwzwLX7jgNJ7t0R
qcURQMC2tioBNAABr7VUCSD+gbfaaQci/oF3ulb2BBD/wA6N7Akg/oGdfrpjk/gHjGbu6CT+AZ/6
0wDEP/Cx2rsBiH/gMzfuECX+AaOaE4H0/wL71HtMOPt/gP3+5w7UcSzY/wscoLt3x+oYOP8HOEyN
xUDiHzjUproRYNW57ylQjtpGAM7/B45R1whA+Q84TkUjwOLOfTOB4lQzApD+A05QyQgw69w3EihS
FSMA3f/AicofAeYs/4GTld4TuGL5D/RR9M4gpv9AT+XuDmb6D/RX6vdCyP4DQ7gtMRW4oPkPGEaB
xQCyf8BwCksF8voHBjUp6bBgXv/AwMpZBvD6B4bXFbIMmPH6B8ZwXcAkgNo/MJbND3d877H42bnv
EVCx7EkAyT9gXMGTgBWzf2B0oZMAcv+ARGI5gMU/ILNZuQOe8AeMktYBhD8glzIEEP6AwybhpBDC
H3CxlwQJf8DJOgSsJp37+oHGbX6acgG0/QARDOlA5v5AjhttX8DqpnNfMYAtmx+qacCclz8Q6Ho2
fvQvpqz8gVCb61GXAkQ/EG4zGWkMmBP9QAlGmAesJrfuqwJwqO7mx/1wr35y/kBxNgMMAvfT6859
HQBO1N1drU78oMj8YnLXuf9+AH1t7iaz+yOGgcVqSuwDdenurq8uVp8NBIv57GJyfdu5/1IA49nc
3d1cT66urqYXD379x+T6+u6WA70BAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADQy/8DB3M7UiWTNckAAAAldEVYdGRh
dGU6Y3JlYXRlADIwMjItMDgtMjlUMDk6NDE6MjErMDA6MDAe9jJzAAAAJXRFWHRkYXRlOm1vZGlm
eQAyMDIyLTA4LTI5VDA5OjQxOjIxKzAwOjAwb6uKzwAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAy
Mi0wOC0yOVQwOTo0MToyMSswMDowMDi+qxAAAAAASUVORK5CYII=" />
</svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
