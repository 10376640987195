
import {  styled } from '@mui/material/styles';
import { ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import Iconify from './Iconify';
import { useStyles } from 'src/styles/components/styles';

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});


export default function LogoutSection({ title, logoutHandler , ...other }) {
    const classes = useStyles();
    return (
        <ListItemStyle
        onClick={logoutHandler}
        >
            <ListItemIconStyle className={classes.logoutListItemMargin}><Iconify
                icon={'fe:logout'}
                className={classes.listItemIcon}
            /></ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
        </ListItemStyle>
    );
}
